<template>
  <div>
    <dialog-form
      ref="uploadLandingPageForm"
      :dialog="dialog"
      :form-title="formTitle"
      :form-scheme="getFormScheme"

      :save-loading="saveLoading"
      :edited-item="currentItem"
      :submit-text="$t('Submit')"
      @changeEditedItem="changeEditedItem"
      @save="upload"
      @close="close"
    />
  </div>
</template>

<script>
import LandingPageSeo from '../components/LandingPageSeo.vue'
// import LandingPageDomains from '../components/LandingPageDomains.vue'

export default {
  name: 'UploadLandingPage',
  data () {
    return {
      dialog: false,
      formScheme: {},
      saveLoading: false,
      inputData: {},
      currentItem: {
        base: '/',
        cdn: 'techforce.cloud',
        type: 'default',
        withPixel: false,
        compressHtml: false,
        withUpload: false
      },
      formTitle: '',
      thankYouPagesList: [
        { value: 'default', text: 'Default' },
        { value: 'cabinet', text: 'With cabinet' }
      ],
      searchDomainsTimeout: undefined,
      searchDomainsLoading: false,
      foundDomains: [],
      domainsQueryTerm: '',
      delayTimer: null
    }
  },
  computed: {
    getFormScheme() {
      return this.formScheme
    },
    getFoundDomains() {
      return this.foundDomains
    }
  },
  watch: {
    inputData (v) {
      this.formScheme.seo.data = v
    },
    currentItem(item) {

    }
  },
  async mounted () {
    this.formTitle = this.$t('Generate landing page')
    // this.currentItem = Object.assign({}, this.editedItem)
    this.formScheme = {
      seo: {
        type: 'component',
        component: LandingPageSeo,
        data: this.inputData,
        md: 12,
        sm: 12
      },
      token: {
        type: 'text',
        label: this.$t('Team token'),
        createRules: 'req|min:2|max:65',
        // updateRules: 'req|min:2|max:64',
        md: 6,
        sm: 6
      },
      affiliate: {
        type: 'text',
        label: this.$t('Affiliate hash'),
        createRules: 'req|min:2|max:32',
        // updateRules: 'req|min:2|max:64',
        md: 6,
        sm: 6
      },
      base: {
        type: 'text',
        label: this.$t('Base href (folder)'),
        createRules: 'req|min:1|max:32',
        md: 2,
        sm: 2
      },
      type: {
        type: 'select',
        label: this.$t('Thankyou page type'),
        createRules: 'req',
        items: this.thankYouPagesList,
        multiple: false,
        clearable: false,
        md: 7,
        sm: 7
      },
      compressHtml: {
        type: 'checkbox',
        label: this.$t('Minify HTML'),
        md: 3,
        sm: 3
      },
      cdn: {
        type: 'text',
        label: this.$t('Form links (CDN)'),
        createRules: 'req|min:2|max:128',
        prefix: 'https://',
        suffix: '{/css/app.css,/js/app.js}',
        md: 12,
        sm: 12
      },
      // domains: {
      //   type: 'component',
      //   component: LandingPageDomains,
      //   md: 11,
      //   sm: 11
      // },
      domains: {
        type: 'combobox',
        // type: 'autocomplete',
        label: this.$t('List of domains to upload this landing page'),
        createRules: [],
        updateRules: [],
        chips: true,
        multiple: true,
        md: 11,
        // hint: this.$t('List of domains to upload this landing page'),
        clearable: true,
        disabled: true,
        loading: false,
        hideNoData: true,
        hideSelected: true,
        returnObject: true,
        items: this.getFoundDomains,
        fn: async (i, e, v) => {
          this.$set(i, 'disabled', true)
          this.$set(i, 'loading', true)
          const prepDomains = this.preparedDomains(v)
          let verifiedDomains = []
          if (prepDomains.length > 0) {
            await this.$toast.success(prepDomains.length + this.$t(' domains sent for checking'))
            verifiedDomains = await this.$store.dispatch('domains/checkAllowedDomains', { domains: prepDomains })
            await this.$toast.success(verifiedDomains.length + this.$t(' domains passed availability check'))
          }
          Object.assign(this.currentItem, { domains: verifiedDomains })
          this.$set(i, 'disabled', false)
          this.$set(i, 'loading', false)
          return v
        }
      },
      withUpload: {
        type: 'checkbox',
        // label: this.$t('Upload to domains'),
        md: 1,
        sm: 1,
        fn: (i, e, v) => {
          this.$refs.uploadLandingPageForm.changeElemOfForm('domains', 'disabled', !v)
          Object.assign(this.currentItem, { domains: [] })
        }
      },
      fbPixel: {
        type: 'text',
        label: this.$t('Facebook pixel'),
        createRules: 'min:2|max:64',
        // updateRules: 'req|min:2|max:64',
        disabled: true,
        md: 5,
        sm: 5
      },
      gPixel: {
        type: 'text',
        label: this.$t('Google pixel'),
        createRules: 'min:2|max:64',
        // updateRules: 'req|min:2|max:64',
        disabled: true,
        md: 6,
        sm: 6
      },
      withPixel: {
        type: 'checkbox',
        // label: this.$t('With pixels'),
        md: 1,
        sm: 1,
        fn: (i, e, v) => {
          this.$refs.uploadLandingPageForm.changeElemOfForm('fbPixel', 'disabled', !v)
          this.$refs.uploadLandingPageForm.changeElemOfForm('gPixel', 'disabled', !v)
          Object.assign(this.currentItem, { gPixel: undefined, fbPixel: undefined })
        }
      }
    }
  },
  methods: {
    changeEditedItem(changes) {},
    async upload(evn) {
      try {
        const v = evn.$refs.form.validate()
        if (v) {
          this.saveLoading = true
          const data = {
            name: this.inputData.alias,
            token: this.currentItem.token,
            affiliate: this.currentItem.affiliate,
            base: this.currentItem.base,
            type: this.currentItem.type,
            cdn: this.currentItem.cdn,
            facebook_pixel: this.currentItem.fbPixel,
            google_pixel: this.currentItem.gPixel,
            compress_html: this.currentItem.compressHtml,
            domains: this.currentItem.domains,
            seo_title: this.inputData.name,
            seo_desc: this.inputData.description,
            seo_img: this.inputData.image
          }
          const response = await this.$store.dispatch('tasksLpg/generate', { data })
          if (response.success === true) {
            this.$toast.success(response.messages)
            this.close()
            await this.$store.dispatch('adminLayout/toggleDialogEventLog', true)
          } else {
            this.$toast.error(response.errors || response.message)
          }
        }
      } catch (e) {
        this.$toast.error(e.response.data.message)
      } finally {
        this.saveLoading = false
      }
    },
    open(inputData = {}) {
      this.dialog = true
      this.inputData = inputData
    },
    close() {
      this.dialog = false
      this.$emit('close', this)
    },
    preparedDomains(str) {
      let domainsStr = (' ' + str).slice(1)
      if (/[\s,]/.test(domainsStr)) {
        domainsStr = domainsStr.split(/[\s,]/)
        if (domainsStr.length > 1) {
          domainsStr = domainsStr.map((d) => d.trim()).filter(Boolean)
        }
      }
      return domainsStr.length > 0 ? (domainsStr instanceof Array ? domainsStr : [domainsStr]) : ''
    }
  }
}
</script>

<style lang="scss">

</style>
